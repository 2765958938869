<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('custForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="custForm" :model="custForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_abbr">
            <el-input
              maxlength="20"
              v-model="custForm.cust_abbr"
              placeholder="请填写客户简称"
              @input="custForm.cust_abbr = helper.keepEngNum2T(custForm.cust_abbr)"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="外销员所属部门" prop="cust_dept_id">
            <el-select v-model="custForm.cust_dept_id" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="指定外销员" prop="cust_stff_id">
            <el-select v-model="custForm.cust_stff_id" placeholder="请选择指定外销员" size="small" filterable>
              <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="客户属性" prop="cust_attr">
            <el-select @change="custAttrChange" v-model="custForm.cust_attr" placeholder="请选择客户属性" size="small">
              <el-option v-for="item in custAttrList" :key="item.code" :label="item.attr" :value="item.attr"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="8">
          <el-form-item label="客户全称" prop="cust_name">
            <el-input
              maxlength="50"
              v-model="custForm.cust_name"
              placeholder="请填写客户全称"
              @input="custForm.cust_name = helper.NotkeepChin(custForm.cust_name)"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="所属国家" prop="cust_country">
            <el-select filterable v-model="custForm.cust_country" placeholder="请选择所属国家">
              <el-option v-for="item in custCountryList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="客户邮箱" prop="cust_mail">
            <el-input
              maxlength="80"
              v-model="custForm.cust_mail"
              @input="custForm.cust_mail = keepEngNum2(custForm.cust_mail)"
              show-word-limit
              placeholder="请填写客户邮箱"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_aid">
            <el-select filterable v-model="custForm.cptt_aid" placeholder="请选择公司抬头">
              <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款抬头" prop="cptt_bid">
            <el-select filterable v-model="custForm.cptt_bid" placeholder="请选择收款抬头">
              <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="联系人" prop="cust_contact">
            <el-input maxlength="20" v-model="custForm.cust_contact" placeholder="请填写联系人" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="手机号" prop="cust_phone">
            <el-input
              maxlength="20"
              v-model="custForm.cust_phone"
              @input="custForm.cust_phone = helper.keepTNum(custForm.cust_phone)"
              placeholder="请填写手机号"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="座机号" prop="cust_tel">
            <el-input
              maxlength="20"
              v-model="custForm.cust_tel"
              @input="custForm.cust_tel = helper.purephone(custForm.cust_tel)"
              placeholder="请填写座机号"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="放款额度" prop="cust_loan">
            <el-input
              maxlength="10"
              v-model="custForm.cust_loan"
              @input="custForm.cust_loan = helper.keepTNum1(custForm.cust_loan)"
              @blur="custForm.cust_loan = helper.reservedDigits(custForm.cust_loan)"
              placeholder="请填写放款额度"
              show-word-limit
            >
              <template slot="append">$</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户账期" prop="cust_creday">
            <el-input
              maxlength="10"
              v-model="custForm.cust_creday"
              @input="custForm.cust_creday = helper.keepTNum(custForm.cust_creday)"
              placeholder="请填写客户账期"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="付款方式" prop="cust_payway">
            <el-select filterable v-model="custForm.cust_payway" placeholder="请选择付款方式">
              <el-option v-for="item in custPaywayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="价格条款" prop="cust_paylaw">
            <el-select filterable v-model="custForm.cust_paylaw" placeholder="请选择价格条款">
              <el-option v-for="item in custPaylwayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="币种" prop="cust_currency">
            <el-select filterable v-model="custForm.cust_currency" placeholder="请选择币种">
              <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="贸易方式" prop="cust_tradeway">
            <el-select filterable v-model="custForm.cust_tradeway" placeholder="请选择贸易方式">
              <el-option v-for="item in custTradewayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="运输方式" prop="cust_shipway">
            <el-select filterable v-model="custForm.cust_shipway" placeholder="请选择运输方式">
              <el-option v-for="item in custShipwayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的国" prop="cust_dcountry">
            <el-select filterable v-model="custForm.cust_dcountry" placeholder="请选择目的国">
              <el-option v-for="item in custCountryList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的港" prop="cust_dport">
            <el-select filterable v-model="custForm.cust_dport" placeholder="请选择目的港">
              <el-option v-for="item in custDportList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="货代简称" prop="inst_abbr">
            <el-input maxlength="10" v-model="custForm.inst_abbr" disabled placeholder="请填写货代简称" show-word-limit>
              <template slot="append">
                <el-link type="primary" class="vg_cursor_hander" @click="dialogVisible = true">双击选择</el-link>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="custPidInputFlag" :md="8">
          <el-form-item label="所属中间商" :rules="[{ required: true }]">
            <el-input disabled v-model="cust_pid_name" show-word-limit placeholder="请填写客户所属中间商" @click.native="custImport()">
              <template slot="append">
                <el-link type="primary" class="vg_cursor_hander">选择</el-link>
              </template>
              <!-- {{cust_pid_name}} -->
            </el-input>
          </el-form-item>
          <el-dialog :title="custTitle" :visible.sync="CustPidFlag" width="70%">
            <custEditMiddleMan @custPID="custPID" @custName="custName" @custCancel="custCancel"></custEditMiddleMan>
          </el-dialog>
        </el-col>

        <el-col :md="8">
          <el-form-item label="客户地址" prop="cust_addr">
            <el-input maxlength="255" v-model="custForm.cust_addr" type="textarea" :rows="1" show-word-limit placeholder="请填写备注">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="客户验货标准" prop="cust_inspstand">
            <el-select filterable v-model="custForm.cust_inspstand" placeholder="请选择客户验货标准">
              <el-option v-for="item in custInspList" :key="item.id" :label="item.label" :value="item.label"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-form-item label="唛头" prop="cust_shipmark">
            <el-input maxlength="255" v-model="custForm.cust_shipmark" type="textarea" :rows="3" show-word-limit placeholder="请填写备注">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-dialog title="机构信息" :visible.sync="dialogVisible" width="70%">
        <InstList @instAbbrChoose="instAbbrChoose"></InstList>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { custAPI } from '@api/modules/cust';
import { optnAPI } from '@api/modules/optn';
import { openAPI } from '@api/modules/open';
import addHeader from '@/views/component/addHeader';
// import inputUser from "@/views/component/inputUser";
import custEditMiddleMan from './Componet/CustEditMiddleMan.vue';
import InstList from './Componet/InstList.vue';

export default {
  name: 'CustAddMain',
  components: {
    addHeader,
    // inputUser,
    InstList,
    custEditMiddleMan
  },
  data() {
    return {
      rules: {
        cust_abbr: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_attr: [{ required: true, trigger: 'blur', message: ' ' }],
        cptt_aid: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cptt_bid: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_stff_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_name: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_country: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_mail: [{ required: true, trigger: ['blur'], message: ' ' }],
        cust_tradeway: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_dept_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_contact: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_phone: [{ required: true, trigger: ['blur', 'change'], message: ' ' }]
      },
      custPidInputFlag: false,
      CustPidFlag: false,
      cust_pid_name: null,
      custTitle: '添加所属中间商客户',
      custForm: {
        cust_no: null,
        cust_name: null,
        cust_country: null,
        cust_abbr: null,
        cust_stff_id: null,
        cust_attr: null,
        cust_attr_code: null,
        cust_addr: null,
        cust_contact: null,
        cust_phone: null,
        cust_tel: null,
        cust_loan: '0.00',
        cust_creday: '0',
        cust_payway: null,
        cust_paylaw: 'FOB',
        cust_currency: 'USD',
        cust_mail: null,
        cust_tradeway: null,
        cust_shipway: 'Sea',
        cust_dcountry: null,
        cust_dport: null,
        cptt_aid: null,
        cptt_bid: null,
        inst_id: null,
        inst_abbr: null,
        cust_shipmark: null,
        cust_dept_id: null,
        cust_pid: null,
        cust_inspstand: null
      },
      dialogVisible: false,
      stffList: [],
      custAttrList: [
        {
          attr: '最终客户',
          code: 'f'
        },
        {
          attr: '中间商',
          code: 'm'
        }
      ],
      custCountryList: [],
      custPaywayList: [],
      custPaylwayList: [
        {
          id: 1,
          param1: 'FOB'
        },
        {
          id: 2,
          param1: 'CIF'
        }
      ],
      custCurrencyList: [
        {
          id: 1,
          param1: 'USD'
        },
        {
          id: 2,
          param1: 'RMB'
        }
      ],
      custTradewayList: [],
      custShipwayList: [],
      custDportList: [],
      cpttList: [],
      deptList: [],
      custInspList: [
        { id: 0, label: '0.65' },
        { id: 1, label: '1.0' },
        { id: 2, label: '1.5' },
        { id: 3, label: '2.5' },
        { id: 4, label: '4.0' },
        { id: 5, label: '6.0' }
      ]
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getStffUser();
      this.getCountry();
      this.getCustPayway();
      // this.getCustPaylway() 获取价格条款，暂写死
      // this.getCustCurrency() 获取币种，暂写死
      this.getCustTradeway();
      this.getCustShipway(); // 获取运输方式
      this.getCustDport(); // 获取目的港
      this.getCpttUser();
      this.getDept();
    },
    // 从ERP获取部门信息
    getDept() {
      get(openAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //关闭选择中间商弹窗
    custCancel() {
      this.CustPidFlag = false;
    },
    //打开选择中间商弹窗
    custImport() {
      if (!this.isShow) {
        this.CustPidFlag = true;
      }
    },
    // custPID子传父值
    custPID(val) {
      if (val) {
        this.custForm.cust_pid = val;
      } else {
        this.custForm.cust_pid = null;
      }
      this.CustPidFlag = false;
    },
    // custIname子传父值
    custName(val) {
      if (val) {
        this.cust_pid_name = val;
      } else {
        this.cust_pid_name = null;
      }
      this.CustPidFlag = false;
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          console.log('进入检验?');
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      if (this.custForm.cust_attr_code == 'f' && this.cust_pid_name == null) {
        this.$notify.warning({
          title: '缺少必填项',
          message: '请填写客户所属中间商'
        });
        return;
      }
      if (this.custForm.cust_attr_code !== 'f') {
        this.custForm.cust_pid = null;
      }
      // const staffForm = this.$refs.userData.getData()
      // let custForm = Object.assign(this.custForm, staffForm)
      console.log('let custform是啥', this.custForm);
      // custForm.personal = this.$cookies.get('userInfo').personal
      post(openAPI.addCust, this.custForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            // const permId = this.$route.query.perm_id
            const props = JSON.parse(this.$UrlEncode.decode(this.$route.query.key));
            this.jump('/cust_edit', {
              key: this.$UrlEncode.encode(
                JSON.stringify({
                  perm_id: props.perm_id,
                  form_id: res.data.data.form_id
                })
              )
            });
            // this.jump(`/cust_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
            this.resetForm('custForm');
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '客户编号已存在'
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('custForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 更换属性code
    custAttrChange(val) {
      for (let i = 0; i < this.custAttrList.length; i++) {
        if (val === this.custAttrList[i].attr) {
          this.custForm.cust_attr_code = this.custAttrList[i].code;
          if (this.custForm.cust_attr_code == 'f') {
            this.custPidInputFlag = true;
          } else {
            this.custPidInputFlag = false;
          }
        }
      }
      if (val === '中间商') {
        this.cust_pid = null;
        this.cust_pid_name = null;
      }
    },
    // 选择货代
    instAbbrChoose(row) {
      let val = JSON.parse(JSON.stringify(row));
      this.custForm.inst_abbr = val.inst_abbr;
      this.custForm.inst_id = val.inst_id;
      this.dialogVisible = false;
    },
    // 获取客户
    // getCustList() {
    //   get(custAPI.getAllCustsV1)
    //     .then((res) => {
    //       if (res.data.code === 0) {
    //         this.custList = res.data.data.list
    //       }
    //     }).catch(() => { })
    // },
    // 从DYJ获取国家
    getCountry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10007 })
        .then(res => {
          if (res.data.code === 0) {
            this.custCountryList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 从ERP获取付款方式
    getCustPayway() {
      get(openAPI.getOptnByPermId, { perm_id: 10013 })
        .then(res => {
          if (res.data.code === 0) {
            this.custPaywayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取价格条款
    // getCustPaylway() {
    //   get(optnAPI.getOptnByPermId, { perm_id: 10014 })
    //     .then((res) => {
    //       if (res.data.code === 0) {
    //         this.custPaylwayList = res.data.data.form.optn_cntt_list
    //       } else {
    //         let mg = res.data.msg;
    //         let tp = 'error';
    //         this.$message({ message: mg, type: tp });
    //       }
    //     })
    //     .catch(res => {
    //       let mg = res.data.msg;
    //       let tp = 'error';
    //       this.$message({ message: mg, type: tp });
    //     })
    // },
    // 获取币种
    // getCustCurrency() {
    //   get(optnAPI.getOptnByPermId, { perm_id: 10015 })
    //     .then((res) => {
    //       if (res.data.code === 0) {
    //         this.custCurrencyList = res.data.data.form.optn_cntt_list
    //       } else {
    //         let mg = res.data.msg;
    //         let tp = 'error';
    //         this.$message({ message: mg, type: tp });
    //       }
    //     })
    //     .catch(res => {
    //       let mg = res.data.msg;
    //       let tp = 'error';
    //       this.$message({ message: mg, type: tp });
    //     })
    // },
    // 从ERP获取贸易方式
    getCustTradeway() {
      get(openAPI.getOptnByPermId, { perm_id: 10016 })
        .then(res => {
          if (res.data.code === 0) {
            this.custTradewayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 从ERP获取运输方式
    getCustShipway() {
      get(openAPI.getOptnByPermId, { perm_id: 10017 })
        .then(res => {
          if (res.data.code === 0) {
            this.custShipwayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 从ERP获取目的港
    getCustDport() {
      get(openAPI.getOptnByPermId, { perm_id: 10018 })
        .then(res => {
          if (res.data.code === 0) {
            this.custDportList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 从ERP获取外销员list
    getStffUser() {
      get(openAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 从ERP获取公司抬头
    getCpttUser() {
      get(openAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //过滤后只保留英语字母和数字加-加_@
    keepEngNum2(str) {
      return str.replace(/[^0-9a-zA-Z-_@.\s]/g, '');
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_mrt1 {
  margin-top: 1px;
}
</style>
